import React from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';

const LinkButton = ({ label, dark, arrowDirection, to, className }) => (
  <Link className={`btn ${dark ? 'dark' : ''} ${className}`} to={to}>
    {label}
    {arrowDirection && (
      <i className={`arrow fas fa-long-arrow-alt-${arrowDirection}`} />
    )}
  </Link>
);

LinkButton.defaultProps = {
  arrowDirection: null,
  className: '',
  dark: false,
};

LinkButton.propTypes = {
  arrowDirection: PropTypes.string,
  className: PropTypes.string,
  dark: PropTypes.bool,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default LinkButton;

import React from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import { graphql } from 'gatsby';

import Document from '../components/Document';
import Section from '../components/Section';
import Markdown from '../components/Markdown';
import LinkButton from '../components/LinkButton';

import { bluishPurple } from '../css-utilities';

const buttonStyle = css.resolve`
  margin: 10px 15px 10px 0px;
`;

const NotFoundPage = ({
  data: {
    datoCms404Page: { seo, title, text, buttons },
  },
}) => (
  <Document seo={seo}>
    <Section>
      <div className="page">
        <span className="prefix">404</span>
        <h1>{title}</h1>
        <Markdown source={text} />

        <div className="buttons">
          {buttons.map((button, i) => (
            <LinkButton
              arrowDirection="right"
              className={buttonStyle.className}
              dark={i === 0 ? true : false}
              key={button.id}
              label={button.title}
              to={button.path}
            />
          ))}
        </div>
      </div>

      {buttonStyle.styles}

      <style jsx>
        {`
          .page {
            width: 100%;
          }
          .prefix {
            font-size: 20px;
            color: ${bluishPurple};
            font-weight: bold;
            font-family: 'Poppins';
          }
          .buttons {
            margin-top: 30px;
          }
        `}
      </style>
    </Section>
  </Document>
);

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  {
    datoCms404Page {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      text
      buttons {
        id
        title
        path
      }
    }
  }
`;

export default NotFoundPage;
